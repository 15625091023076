import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import { Check } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getAllDealNames from 'redux/actions/Common/getAllDealNames';

const DealSelect = ({
  selectedCrmDeal,
  setSelectedCrmDeal,
  showCreateNew,
  // crmDeals,
  ...rest
}) => {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;
  const [crmDeals, setCrmDeals] = useState([]);

  useEffect(() => {
    dispatch(
      getAllDealNames((dealNames) => {
        setCrmDeals(dealNames);
      }),
    );
  }, []);

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        labelId="deal-select-label"
        id="deal-select"
        name="selectedCrmDeal"
        MenuProps={{
          disableScrollLock: true,
        }}
        onChange={(e) => {
          if (e.target.value === 'createnew') {
            history.push(`/teams/${teamId}/deals?new=true`);
          } else {
            setSelectedCrmDeal(e.target.value);
          }
        }}
        value={selectedCrmDeal}
      >
        <MenuItem value={'select'}>
          <Typography>Select a Deal</Typography>
        </MenuItem>
        {showCreateNew && crmDeals && crmDeals?.length === 0 ? (
          <MenuItem value={'createnew'}>
            <Typography>+ Create New Deal</Typography>
          </MenuItem>
        ) : null}
        {crmDeals && crmDeals?.length > 0
          ? crmDeals?.map((deal) => (
              <MenuItem key={deal?.id} value={deal?.id}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>{deal?.name}</Typography>
                  {/* <Typography>
                                      {moment(deal?.createdOn).format(
                                        'DD MMM YYYY',
                                      )}
                                    </Typography> */}
                </Box>
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
};

DealSelect.propTypes = {
  selectedCrmDeal: PropTypes.string,
  setSelectedCrmDeal: PropTypes.func,
  showCreateNew: PropTypes.bool,
  // crmDeals: PropTypes.object,
};

export default DealSelect;
